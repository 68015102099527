.container {
  width: 100%;
  padding: 0 20rem;

  @include devices(tablet) {
    padding: 0 2rem;
  }
}

.items-highlighter {
  color: var(--highlight-primary);
  font-family: 'eurostileBold';
}

.carousel {
  position: relative;
  .background {
    background-color: transparent !important;
  }
  .carousel__container {
    height: 65rem !important;

    @include devices(tablet) {
      height: 40rem !important;
    }

    .carousel__container--item {
      height: 100% !important;
      background-color: transparent !important;
      box-shadow: none !important;
      width: 45rem !important;
      border-radius: 0 !important;

      @include devices(tablet) {
        width: 30rem !important;
      }

      img {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        width: 100% !important;
        box-shadow: none !important;
        margin: 0 !important;
        border-radius: 0 !important;
      }

      .carousel-text-cont {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(-360deg, rgba(0, 0, 0, 0.6), transparent);

        .text {
          font-size: 12rem;
          font-weight: 600;
          color: var(--text-primary);
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
  }
}
