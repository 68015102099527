.Header {
  position: fixed;
  top: 0;
  height: 7rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8rem;
  z-index: 999999;
  background-color: transparent;
  transition-property: all;
  transition-duration: 0.3s;
  background: linear-gradient(180deg, #000, transparent);

  @include devices(tablet) {
    padding: 6rem 2rem;
  }
}

.navOpened {
  background-color: var(--bg-1);
}

.Header-scrolled {
  padding: 6rem;
  transition: all 0.5s ease;
  background: linear-gradient(180deg, #000, transparent);

  @include devices(tablet) {
    padding: 6rem 2rem;
  }

  .Logo {
    .img1 {
      height: 7rem;

      @include devices(tablet) {
        height: 6rem;
      }
    }

    .slogan {
      display: none;
    }
  }
}

.Logo {
  display: flex;
  align-items: center;
  .img1 {
    height: 8rem;
    @include devices(tablet) {
      height: 8rem;
    }
  }

  .slogan {
    border-left: 0.1rem solid var(--highlight-primary);
    margin-left: 2rem;
    padding-left: 2rem;
    img {
      height: 4rem;
      @include devices(tablet) {
        display: none;
      }
    }
  }
}

.Nav {
  position: absolute;
  display: flex;
  align-items: center;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0 0;
  background: var(--bg-1);
  box-shadow: var(--box-shadow);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100vh;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;

  @include devices(tablet) {
    flex-direction: column;
    justify-content: flex-start;
  }

  .active {
    transform: scale(1.1);
    color: var(--highlight-primary);
  }
}

.nav-items-cont {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include devices(tablet) {
    width: 100%;
  }
}

.Nav a {
  color: #fff;
  font-size: 5rem;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 5rem;

  @include devices(tablet) {
    font-size: 2.4rem;
    margin-top: 2rem;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.Nav a:hover {
  transform: scale(1.1);
  color: var(--highlight-primary);
}

.Nav button {
  padding: 10px;
  outline: none;
  border: none;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  background-color: rgba(255, 0, 0, 0.5);
  box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  transition: 70ms;
}

.Nav button:active {
  transform: translateY(3px);
  box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

.Burger {
  grid-area: burger;
  padding: 0;
  justify-self: end;
  font-size: 4rem;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
  color: var(--text-primary);
  height: 2rem;

  @include devices(tablet) {
    display: block;
    font-size: 3rem;
  }
}

.Burger:active {
  transform: scale(1.2);
}

.NavAnimation-enter {
  opacity: 0;
  transform: scale(0.5);
}
.NavAnimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 350ms, transform 350ms;
}
.NavAnimation-exit {
  opacity: 1;
}
.NavAnimation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.hamburger-react {
  margin-right: 4rem;
  @include devices(tablet) {
    margin-right: 0 !important;
  }
  div {
    width: 6rem !important;
    height: 1px !important;

    @include devices(tablet) {
      width: 5rem !important;
    }
  }
}
