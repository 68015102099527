.home-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .banner-cont {
    width: 100%;
    height: 100vh;
    position: relative;

    @include devices(tablet) {
      height: 98vh;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      .content-box {
        width: 70%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        @include devices(tablet) {
          width: 80%;
        }

        .text {
          color: var(--text-primary);
          font-size: 6.5rem;
          width: 40%;
          text-align: left;
          font-family: 'eurostileBold';
          line-height: 8rem;
          letter-spacing: 0.5rem;

          @include devices(tablet) {
            font-size: 3rem;
            width: 100%;
            line-height: 4rem;
          }
        }

        .button-cont {
          display: flex;
          align-items: center;
          font-size: 1.8rem;
          margin-top: 5rem;
          font-family: 'eurostileBold';
          cursor: pointer;
          transition: 1s;

          .icon {
            font-size: 6rem;
            margin-right: 2rem;

            @include devices(tablet) {
              font-size: 6rem;
            }
          }

          &:hover {
            color: var(--highlight-primary);
            transform: scale(1.1);
          }
        }
      }

      .scroller-cont {
        display: flex;
        justify-content: flex-end;
        width: 80%;
        position: absolute;
        top: 85%;

        .scroll-animate-view {
          display: flex;
          align-items: center;
          cursor: pointer;

          .content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .cont-1 {
              font-size: 1.4rem;
              letter-spacing: 0.2rem;
            }

            .cont-2 {
              font-size: 1.2rem;
              margin-top: 0.5rem;
              color: var(--text-secondary);
              letter-spacing: 0.2rem;
            }
          }

          .icon {
            font-size: 3rem;
            margin-left: 1rem;
            color: var(--text-secondary);
            animation: up-down 0.5s infinite alternate;
          }
        }
      }
    }
  }
  .home-contents-cont {
    .about-us-cont {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15rem 0 0 0;

      @include devices(tablet) {
        height: 100%;
        padding: 2rem;
        margin: 10rem 0;
      }

      .text-cont {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include devices(tablet) {
          width: 90%;
        }

        .header {
          font-size: 4.2rem;
          margin-bottom: 3rem;
          font-family: 'eurostileBold';
        }

        .content {
          font-size: 1.8rem;
          font-family: 'Poppins';
          color: var(--text-secondary);
          margin-bottom: 2rem;
        }
      }
    }

    .certificate-cont {
      display: flex;
      justify-content: space-between;
      margin: 5rem 0 5rem 0;

      @include devices(tablet) {
        flex-wrap: wrap;
        justify-content: center;
      }

      img {
        height: 20rem;
        object-fit: contain;
        margin-bottom: 2rem;
        transition: 0.5s all;
        border-radius: 0.2rem;

        @include devices(tablet) {
          height: 22rem;
          margin-right: 1rem;
        }

        &:hover {
          transform: scale(2.2);

          @include devices(tablet) {
            transform: none;
          }
        }
      }
    }
  }

  .gallery-cont {
    margin: 10rem 0;
  }
}

@media only screen and (min-width: 1441px) {
  .home-contents-cont {
    max-width: 1440px;
    margin: 0 auto;
  }
}

@keyframes up-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(8px);
  }
}
