.icap-section {
  height: 100%;
  padding: 10rem 0;

  .icap-tiles-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .icap-tiles-header {
      font-size: 4rem;
      color: var(--secondary-highlight);
      font-weight: 500;
      letter-spacing: 0.2rem;
      font-family: 'Rajdhani', sans-serif;
      text-transform: uppercase;
    }

    .icap-desc {
      margin-top: 3rem;
      display: flex;
      justify-content: center;

      p {
        text-align: center;
        width: 80%;
      }
    }

    .tiles-container {
      display: flex;
      padding: 2rem;
      perspective: 150rem;
      margin-top: 3rem;
      width: 95%;
      flex-wrap: wrap;
      justify-content: center;

      @include devices(tablet) {
        width: 100%;
      }

      .tiles {
        --rotate: 5deg;
        background: var(--bg-2);
        border-radius: 1rem;
        box-shadow: 0 0 10px rgb(0 0 0 / 20%);
        padding: 0.5rem;
        position: relative;
        text-align: center;
        transform: rotateX(var(--rotate));
        transform-origin: top center;
        transition: transform 0.2s;
        height: 25rem;
        width: 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 2rem;
        margin-bottom: 3rem;

        @include devices(tablet) {
          margin-left: 0;
          width: 49%;
          margin-right: 0.2rem;
        }

        .user-image {
          width: 100%;
          height: 20rem;
          object-fit: cover;
        }
      }

      .employee-name {
        color: var(--text-primary);
        font-size: 1.8rem;
        margin: 1rem 0;
        font-family: 'eurostileBold';
        letter-spacing: 0.1rem;
      }
    }
  }
}

@media only screen and (min-width: 1441px) {
  .icap-section {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.carousel-container {
  height: 90vh;
}
