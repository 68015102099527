.body--dark {
  --highlight-primary: #ff512f;
  --highlight-secondary: #fff;
  --highlight-green: #2bb25a;
  --highlight-error: #d8000c;
  --highlight-primary-opaque: 251, 162, 2;
  --highlight-secondary-opaque: 255, 255, 255;
  --bg-1: #000;
  --bg-2: #171717;
  --bg-3: #353935;
  --bg-4: #36454f;
  --bg-4-inverse: #fbfafb;
  --text-primary: #f0f0f0;
  --text-primary-rgb: 240, 240, 240;
  --bg-1-rgb: 8, 25, 44;
  --gradient-2: #ff5800;
  --gradient-1: #fba202;
  --text-secondary: hsla(0, 0%, 100%, 0.6);
  --box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  --box-shadow-header: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
