.services-cont {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .container-main {
    display: flex;
    width: 80%;
    justify-content: space-between;

    .left-cont {
      width: 40%;

      @include devices(tablet) {
        width: 100%;
      }

      .content-with-icons {
        display: flex;
        margin-bottom: 12rem;

        @include devices(tablet) {
          flex-direction: column;
          margin-bottom: 0;
        }

        .image-icon {
          margin-right: 1rem;
          height: 6rem;
          width: 6rem;

          @include devices(tablet) {
            margin-bottom: 1rem;
          }
        }

        .image-icon-2 {
          margin-right: 1rem;
          height: 4rem;
          width: 4rem;

          @include devices(tablet) {
            margin-bottom: 1rem;
          }
        }

        .text-cont {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @include devices(tablet) {
            margin-bottom: 5rem;
          }

          .header {
            font-size: 3.8rem;
            margin-bottom: 3rem;
            font-family: 'eurostileBold';
            text-align: left;
          }

          .content {
            font-size: 1.6rem;
            font-family: 'Poppins';
            color: var(--text-secondary);
            text-align: left;
            border-bottom: 0.1rem solid var(--highlight-primary);
            padding-bottom: 3rem;
          }

          .explorer {
            margin-top: 3rem;
            font-family: 'eurostile';
            font-size: 1.8rem;
            letter-spacing: 0.2rem;
            transition: 0.5s;
            cursor: pointer;
            color: var(--text-secondary);
            display: flex;
            align-items: center;

            .icon {
              margin-left: 1rem;
              font-size: 1.8rem;
              margin-top: 0rem;
              color: var(--highlight-primary);
            }

            &:hover {
              transform: scale(1.1);
              color: var(--text-primary);
            }
          }
        }
      }
    }

    .secondary-left-cont {
      display: flex !important;
      width: 100% !important;
      justify-content: space-between;

      @include devices(tablet) {
        flex-direction: column;
        justify-content: center;
      }

      .content-with-icons {
        width: 32%;

        @include devices(tablet) {
          width: 100%;
        }

        .text-cont {
          .header {
            height: 8rem;
          }

          .content {
            height: 18rem;
          }
        }
      }
    }

    .right-cont {
      width: 55%;
      height: 65rem;

      @include devices(tablet) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
