@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/fonts';

@import 'base/base';
@import 'base/typography';

@import 'layout/header';
@import 'layout/footer';

@import 'components/buttons';
@import 'components/splash-screen';

@import 'pages/home';
@import 'pages/services';
@import 'pages/teams';
@import 'pages/coming_soon';

.app-routes {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--text-primary);
  background-color: var(--bg-1);
  position: relative;
  scroll-behavior: smooth;
}

.SimpleVideo {
  background-color: black;
}

.video-full-screen-cont {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 99;
  right: 0;

  .close-btn-cont {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 2rem 10rem;
    z-index: 9999;

    @include devices(tablet) {
      padding: 2rem;
    }

    .icon {
      font-size: 4rem;
      cursor: pointer;
      color: white;
    }
  }

  video {
    width: 100%;
    height: 96vh;
    object-fit: cover;
  }

  .SimpleVideo-playPause {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    height: 6rem;

    button {
      height: 100%;
      width: 10rem;
      background-color: transparent;
      border: 1px solid white;
      color: white;
      border-radius: 2rem;
    }
  }
}
